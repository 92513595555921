import styles from "./PrimaryBttn.module.css";

function PrimaryBttn({ text, onClick, ...rest }) {
  return (
    <button className={styles.primaryBttn} onClick={onClick} {...rest}>
      {text}
    </button>
  );
}

export default PrimaryBttn;
