import styles from "./Cards.module.css";

function CardsComponent({ heading, text, image, color, index }) {
  return (
    <div
      className={styles.container}
      style={{ backgroundColor: color }}
      key={index}
    >
      <img className={styles.image} src={image} alt="" />
      <h1 className={styles.heading}>{heading}</h1>
      <h6 className={styles.text}>{text}</h6>
    </div>
  );
}

export default CardsComponent;
