import './App.css';
import Home from './Comonents/Home/Home'
import { Routes, Route } from 'react-router-dom';
import Login from './Comonents/LoginPage/Login';
import LayoutComponent from './Comonents/LayoutComponent.js/LayoutComponent';
import ContactPage from './Comonents/ContactPage/ContactPage';
import Pricing from './Comonents/Pricing/Pricing';

function App() {

  return (
      <Routes>
        <Route path='/' element={<LayoutComponent><Home /></LayoutComponent>} />
        <Route path='/login' element={<Login />} />
        <Route path='/contactPage' element={<ContactPage />} />
        <Route path='/pricing' element = {<LayoutComponent><Pricing /></LayoutComponent>} />
      </Routes>
  );
}

export default App;
