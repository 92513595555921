import React, { useState } from "react";
import Modal from "react-responsive-modal";
import styles from "./Footer.module.css";
import { Link } from "react-router-dom";
import ContactPage from "../ContactPage/ContactPage";

function FooterComponent() {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  return (
    <div className={styles.container}>
      <h1 style={{ fontWeight: 800 }}>ClassifyConnect</h1>
      <ul>
        <li>
          <div to="/contactPage" className={styles.link} onClick={onOpenModal}>
            Contact us
          </div>
        </li>
        <li>
          <Link className={styles.link}>Privacy Policy</Link>
        </li>
        <li>
          <Link className={styles.link}>Terms and Condition</Link>
        </li>
      </ul>
      <Modal
        open={open}
        onClose={onCloseModal}
        center
        styles={{
          modalContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          modal: {
            display: "block",
            minWidth: "60%",
            margin: "auto",
            paddingTop: 50,
            paddingBottom: 50,
            borderRadius: 10,
          },
        }}
      >
        <ContactPage />
      </Modal>
    </div>
  );
}

export default FooterComponent;
