import React from "react";
import styles from "./ContactPage.module.css";
import { FaPhoneAlt } from "react-icons/fa";
import { MdMail } from "react-icons/md";

export const ContactPage = () => {
  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        Get In <span>Touch</span> With Us
      </div>

      <div className={styles.contactDetails}>
        <FaPhoneAlt className={styles.icon} />
        <div>
          <ul>
            <li>Call us at</li>
            <li>+91 8109833593</li>
          </ul>
        </div>
      </div>

      <div className={styles.contactDetails}>
        <MdMail className={styles.icon} />

        <div>
          <ul>
            <li>Drop a Mail at</li>
            <li>akashkr2929@gmail.com</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
