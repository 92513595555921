
import React, { useState } from "react";
import PricingCard from "./PricingCard";
import styles from "./Pricing.module.css";
import PrimaryBttn from "../Buttons/PrimaryBttn";

const Pricing = () => {
  const [month, setMonth] = useState(0);
  let pricingObject = [
    {
      id: 1,
      heading: "Startup",
      price: "8",
      listHeading: "Perfect for small teams or startups just getting started.",
      listbodies: [
        "Access to all core features",
        "Event and sports scheduling",
        "Hobby circles and team-building tools",
        "Up to 200 users",
      ],
      buttonText: "Join Now",
    },
    {
      id: 2,
      heading: "Growing Team",
      price: "17",
      listHeading:
        "Designed for growing teams looking for more advanced tools.",
      listbodies: [
        "Everything in Basic, plus:",
        "Advanced control and management tools",
        "Customizable notifications and reminders",
        "Dedicated customer support",
        "Up to 500 users",
      ],
      buttonText: "Join Now",
    },
    {
      id: 3,
      heading: "Enterprise",
      price: null,
      subHeader: "Contact Us",
      listHeading:
        "Tailored solutions for large organizations with complex needs.",
      listbodies: [
        "Everything in Pro, plus",
        "Unlimited users",
        "Custom integrations and API access",
        "Priority customer support",
        "Advanced security features",
      ],
      buttonText: "Contact Us",
    },
  ];

  const text1 = (
    <span>
      <span style={{ marginRight: 20, color: month === 0 ? "#fff" : "#000" }}>
        Yearly
      </span>
      <span
        className={styles.roundYearly}
        style={{ background: month === 0 ? "#fff" : "#e7e7e7" }}
      >
        Save upto 25%
      </span>
    </span>
  );

  return (
    <div className={styles.container}>
      <div className={styles.details1}>
        <h2>
          Flexible <span>Pricing</span> for Teams of All <span>Sizes</span>
        </h2>
        <p>
          Find the perfect plan for your team! Whether you're a small business
          or a large organization, we offer flexible pricing that fits your
          needs.
        </p>

        <div className={styles.switch}>
          <PrimaryBttn
            text={text1}
            className={styles.yearly}
            style={{ background: month === 0 ? "#000" : "#fff" }}
            onClick={() => setMonth(0)}
          />
          <PrimaryBttn
            text="Monthly"
            className={styles.monthly}
            style={{
              background: month === 1 ? "#000" : "#fff",
              color: month === 1 ? "#fff" : "#000",
            }}
            onClick={() => setMonth(1)}
          />
        </div>
      </div>
      <div className={styles.cards}>
        {pricingObject.map((item) => (
          <PricingCard
            key={item.id}
            heading={item.heading}
            price={item.price}
            listHeading={item.listHeading}
            listbodies={item.listbodies}
            buttonText={item.buttonText}
            subHeader={item.subHeader}
          />
        ))}
      </div>
      <div className={styles.details2}>
        <h2>Let’s Build Stronger Connections Together!</h2>
        <p>
          Experience the benefits of working alongside friends. Join our
          community and see how easy it is to enjoy life beyond 9-5.
        </p>
        <PrimaryBttn text="Join Now" style={{ width: 260 }} />
      </div>
    </div>
  );
};

export default Pricing;
