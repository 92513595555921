import React, { useState } from "react";
import styles from "./Navbar.module.css";
import { Link } from "react-router-dom";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Login from "../LoginPage/Login";

function NavbarComponent() {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const pathname=window.location.pathname
  return (
    <div className={styles.container}>
      <div>
        <span id={styles.classify}>Classify</span>
        <span id={styles.connect}>Connect</span>
      </div>
      <div className={styles.navLink}>
        <ul>
          {pathname !=="/" && <li>
           <Link to="/" className={styles.link}>
              Home
            </Link>
          </li>}
          {pathname !=="/pricing" && <li>
            <Link to="/pricing" className={styles.link}>
              Pricing
            </Link>
          </li>}
          <li>
            <div className={styles.link} onClick={onOpenModal}>
              Login
            </div>
          </li>
        </ul>
      </div>
      <Modal
        open={open}
        onClose={onCloseModal}
        center
        styles={{
          modalContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          modal: {
            display: "block",
            minWidth: "60%",
            margin: "auto",
            paddingTop: 100,
            paddingBottom: 100,
            borderRadius: 10,
          },
        }}
      >
        <Login />
      </Modal>
    </div>
  );
}

export default NavbarComponent;
