import React from "react";
import styles from "./PricingCard.module.css";
import PrimaryBttn from "../Buttons/PrimaryBttn";

const PricingCard = ({
  heading,
  price,
  listHeading,
  listbodies,
  buttonText,
  subHeader,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.inerbody}>
        <h2 className={styles.heading}>{heading}</h2>

        {price != null ? (
          <p className={styles.price}>
            ${price}
            <span>/month</span>
          </p>
        ) : null}
        {subHeader ? <p className={styles.price}>{subHeader}</p> : null}
        <p className={styles.listHeading}>{listHeading}</p>
        <ul>
          {listbodies.map((list) => (
            <li className={styles.lists}>{list}</li>
          ))}
        </ul>
      </div>
      <PrimaryBttn
        text={buttonText}
        style={{ marginTop: "auto", width: "100%", background: "#FF5D06" }}
      />
    </div>
  );
};


export default PricingCard;
