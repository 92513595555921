import styles from "./Login.module.css";
import { FaMicrosoft } from "react-icons/fa";
import { IoLogoGoogle } from "react-icons/io5";

function Login() {
  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        Simplified <span>Login</span> with <br /> your <span>work</span> account
      </div>
      <div className={styles.loginMethods}>
        <div className={styles.microsoft}>
          <FaMicrosoft className={styles.icons} />
          <p>Contiue with Microsoft</p>
        </div>

        <div className={styles.google}>
          <IoLogoGoogle className={styles.icons} />
          <p>Contiue with Google</p>
        </div>
      </div>
    </div>
  );
}

export default Login;
