import NavbarComponent from "../Navbar/NavbarComponent"
import FooterComponent from "../Footer/FooterComponent"


function LayoutComponent({children}){
    return(
        <div style={{height:"100dvh",display:'flex',flexDirection:'column'}}>
            <NavbarComponent />
           <div style={{flex:1}}> {children}</div>
            <FooterComponent />
        </div>
    )
}

export default LayoutComponent;